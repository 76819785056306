import { cn } from 'components/common/utils/tailwindUtils'
import { TwMainGridWrapper } from 'components/modules/twComponent/twLayout.module'
import { User } from 'firebase/auth'
import ProgramCardPanel from 'pages/Program/ProgramCard'
import { LoyaltyCardStamper } from 'pages/card/LoyaltyCardStamper'
import { useMemo } from 'react'
import DocumentTitle from 'react-document-title'
import { useNavigate, useParams } from 'react-router-dom'
import NotificationPanel from 'ui/NotificationPanel'
import { CardRefsByConfig } from '../functions/src/data/common'
import { useUserTeamsStore } from './UseMerchantStore'
import {
  FirebaseDb,
  useDatabaseNullableRefLiveValue,
  useFirebase,
} from './components/common/Firebase'
import { useUserNullable } from './components/common/UseUser'
import { firebaseConfig } from './services/FirebaseConfig'

export function CustomerPage() {
  const { customerId, merchantId } = useParams()
  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const loyaltyAuth = firebase.auth
  const { user, SignIn } = useUserNullable({ auth: loyaltyAuth })
  const userTeams = useUserTeamsStore(user, firebaseDb)
  const navigate = useNavigate()

  const merchants = useMemo(() => {
    return (
      merchantId ? [merchantId]
      : userTeams.type === 'SUCCESS' && userTeams.teams ?
        Object.entries(userTeams.teams.permissions)
          .filter(([_, member]) => member?.permission)
          .map(([merchantId]) => merchantId)
      : []
    )
  }, [userTeams, merchantId])

  return (
    <DocumentTitle title={'Instant Loyalty - Card'}>
      <section className='layout max-w-[31.25rem]'>
        <TwMainGridWrapper className='min-h-[80svh]'>
          <NotificationPanel
            className='col-span-12 hidden md:col-span-3 md:block'
            firebaseDb={firebaseDb}
            merchantId={merchantId}
            user={user ?? undefined}
          />
          {customerId && (
            <MerchantCustomerCardsPanel
              className='col-span-12 sm:bg-white md:col-span-9'
              merchantIds={merchants}
              customerId={customerId}
              firebaseDb={firebaseDb}
              user={user}
            />
          )}
        </TwMainGridWrapper>
      </section>
    </DocumentTitle>
  )
}

const MerchantCustomerCardsPanel = (props: {
  merchantIds: string[]
  customerId: string
  firebaseDb: FirebaseDb
  user: User | undefined | null
  className?: string
}) => {
  return (
    <div
      className={cn(
        `no-scrollbar col-span-12 flex w-full flex-col overflow-y-auto p-2 py-2 pb-16 pt-4 lg:rounded lg:pb-2
        lg:shadow-md`,
        props.className,
      )}>
      <h2 className='mb-4 pt-0 text-xl font-semibold lg:bg-white lg:p-2'>Customer&apos;s Cards</h2>
      {props.merchantIds.map((merchantId) => (
        <MerchantCustomerCards
          key={merchantId}
          {...props}
          merchantId={merchantId}
        />
      ))}
    </div>
  )
}

const MerchantCustomerCards = (props: {
  merchantId: string
  customerId: string
  firebaseDb: FirebaseDb
  user: User | undefined | null
}) => {
  const customerCards = useCustomerCardsStore(props.merchantId, props.customerId, props.firebaseDb)
  return (
    <>
      {customerCards.type === 'SUCCESS' &&
        customerCards.cardsData &&
        Object.values(customerCards.cardsData).map((cardRef) => (
          <LoyaltyCardStamper
            key={cardRef.cardId}
            merchantId={props.merchantId}
            firebaseDb={props.firebaseDb}
            user={props.user}
            id={cardRef.cardId}
          />
        ))}
      {customerCards.type === 'FAIL' && 'Something went wrong'}
      {customerCards.type === 'PENDING' && 'Loading Customer...'}
    </>
  )
}

function useCustomerCardsStore(merchantId: string, customerUserId: string, firebaseDb: FirebaseDb) {
  const ref = useMemo(() => {
    return firebaseDb.getRef(`merchantsPrivate/${merchantId}/users/${customerUserId}`)
  }, [merchantId, customerUserId, firebaseDb])

  const cardsData = useDatabaseNullableRefLiveValue<CardRefsByConfig>({ ref })

  return useMemo(() => {
    if (cardsData instanceof Error) {
      return { type: 'FAIL' } as const
    } else if (cardsData === undefined) {
      return { type: 'PENDING' } as const
    } else {
      return {
        type: 'SUCCESS',
        cardsData,
      } as const
    }
  }, [cardsData])
}
