import { Image } from '@chakra-ui/react'
import { cn } from 'components/common/utils/tailwindUtils'
import { ICard } from 'types/card.module'

const MerchantCard: React.FC<{
  cardConfig: ICard
  cardClassName?: string
  className?: string
  onClick: () => void
}> = ({ cardConfig, cardClassName, className, onClick }) => {
  return (
    <div className={cn('flex w-26 min-w-26 flex-col gap-2 p-[8px] md:w-36 md:min-w-36', className)}>
      {cardConfig.logo && (
        <Image
          draggable={false}
          src={cardConfig.logo}
          className={cn(
            `aspect-square w-full overflow-hidden rounded-2xl object-contain p-2 md:rounded-3xl
            xl:hover:cursor-pointer`,
            cardClassName,
          )}
          onClick={onClick}
          style={{
            background: cardConfig.primaryColor,

            border: `2px solid ${cardConfig.secondaryColor}`,
          }}
        />
      )}
      <div className='line-clamp-2 w-full px-2 text-csm font-medium'>{cardConfig.name}</div>
    </div>
  )
}
export default MerchantCard
