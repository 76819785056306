export const rgbToHex = ({
  r,
  g,
  b,
  a,
}: {
  r: number
  g: number
  b: number
  a?: number
}): string => {
  const toHex = (value: number) => value.toString(16).padStart(2, '0').toUpperCase()

  const hexR = toHex(r)
  const hexG = toHex(g)
  const hexB = toHex(b)
  const hexA = a !== undefined ? toHex(Math.round(a * 255)) : '' // Handle alpha if provided

  return `#${hexR}${hexG}${hexB}${hexA}`
}

export const hexToRgb = (hex: string) => {
  if (typeof hex !== 'string' || !hex.startsWith('#')) {
    return null
  }

  hex = hex.replace('#', '')

  // Handle shorthand hex (#RGB)
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('')
  } else if (hex.length === 4) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('')
  }

  if (hex.length !== 6 && hex.length !== 8) {
    return null
  }

  if (!/^[0-9A-F]{6,8}$/i.test(hex)) {
    return null
  }

  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)
  const a = hex.length === 8 ? Math.round((parseInt(hex.slice(6, 8), 16) / 255) * 100) / 100 : 1

  return { r, g, b, a }
}

interface ColorWeight {
  color: string
  weight?: number
}

export function colorDistance(primaryColor: string, compareColor: string | ColorWeight) {
  // Handle both string and ColorWeight inputs
  const compareHex = typeof compareColor === 'string' ? compareColor : compareColor.color
  const weight = typeof compareColor === 'string' ? 1 : (compareColor.weight ?? 1)

  // Convert hex to RGB for both colors
  const hex1 = primaryColor.replace('#', '')
  const hex2 = compareHex.replace('#', '')

  const r1 = parseInt(hex1.substring(0, 2), 16)
  const g1 = parseInt(hex1.substring(2, 4), 16)
  const b1 = parseInt(hex1.substring(4, 6), 16)

  const r2 = parseInt(hex2.substring(0, 2), 16)
  const g2 = parseInt(hex2.substring(2, 4), 16)
  const b2 = parseInt(hex2.substring(4, 6), 16)

  // Calculate weighted Euclidean distance between the colors in RGB space
  const distance = Math.sqrt(
    weight * (Math.pow(r2 - r1, 2) + Math.pow(g2 - g1, 2) + Math.pow(b2 - b1, 2)),
  )

  // Normalize to 0-1 range (max possible distance is sqrt(255^2 * 3))
  return distance / (Math.sqrt(3) * 255 * Math.sqrt(weight))
}

export function getClosestColor(primaryColor: string, colors: (string | ColorWeight)[]) {
  return colors.reduce((closest, color) => {
    return colorDistance(primaryColor, color) < colorDistance(primaryColor, closest) ?
        color
      : closest
  }, colors[0])
}
