import clsx from 'clsx'
import Transition from 'components/Transition'
import { getClosestColor } from 'components/common/utils/colorUtils'
import { cn } from 'components/common/utils/tailwindUtils'
import { ReactComponent as PlusSVG } from 'icons/icons-plus.svg'
import { ReactComponent as LogoBlackBg } from 'icons/logo-BlackBg.svg'
import { ReactComponent as LogoGreenBg } from 'icons/logo-GreenBg.svg'
import { ReactComponent as LogoGreenLightBg } from 'icons/logo-GreenLightBg.svg'
import { ReactComponent as LogoWhiteBg } from 'icons/logo-WhiteBg.svg'
import { ReactComponent as StarSVG } from 'icons/star.svg'
import { ReactComponent as UnknownSVG } from 'icons/unknown.svg'
import { TwCardHome, TwTitleCardHome } from 'pages/Home/twHome.module'
import React, { useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { ICard } from 'types/card.module'

const Tooltip: React.FC<{ show: boolean; message: string }> = ({ show, message }) => {
  if (!show) return null

  return (
    <div
      className={cn(
        'pointer-events-none h-fit w-fit rounded-lg bg-black/80 px-4 py-2 text-sm text-white shadow-lg',
        !show && 'hidden',
      )}>
      <span className='font-bold'>Reward</span> {message}
    </div>
  )
}

const Card: React.FC<ICard> = (props) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [scale, setScale] = React.useState(1)

  React.useEffect(() => {
    const updateScale = () => {
      if (containerRef.current) {
        const parent = containerRef.current.parentElement
        if (parent) {
          const computedStyle = window.getComputedStyle(parent)
          const paddingLeft = parseFloat(computedStyle.paddingLeft)
          const paddingRight = parseFloat(computedStyle.paddingRight)
          const paddingTop = parseFloat(computedStyle.paddingTop)
          const paddingBottom = parseFloat(computedStyle.paddingBottom)

          const parentWidth = parent.clientWidth - paddingLeft - paddingRight
          const parentHeight = parent.clientHeight - paddingTop - paddingBottom
          const cardWidth = 400 // Base card width (25rem)
          const cardHeight = 250 // Base card height (aspect ratio 16/10)

          const scaleX = parentWidth / cardWidth
          const scaleY = parentHeight / cardHeight
          const newScale = Math.min(1, Math.min(scaleX, scaleY))

          setScale(newScale)
        }
      }
    }

    updateScale()
    window.addEventListener('resize', updateScale)
    return () => window.removeEventListener('resize', updateScale)
  }, [])

  return (
    <div
      ref={containerRef}
      style={{ zoom: scale }}>
      <InternalCard {...props} />
    </div>
  )
}
const InternalCard: React.FC<ICard> = ({
  primaryColor,
  name,
  logo,
  isCreate,
  onClick,
  markablePoints: points,
  secondaryColor,
  hasData,
  editable,
  onClickClaim,
  offers,
  isPreview,
  description,
  onSessionMarkedPointsChange,
  sessionMarkedPoints,
  storedMarkedPoints,
  templateId,
}) => {
  const isCreateButton = isCreate && !hasData
  const isClaimButton =
    !isCreate &&
    !isPreview &&
    ((sessionMarkedPoints ?? 0) >= points.length || (storedMarkedPoints ?? 0) >= points.length)
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <Transition>
      <TwCardHome
        onClick={(e) => {
          if (isCreateButton) onClick?.(e)
        }}
        onPointerDown={() => setShowTooltip(true)}
        onPointerUp={() => setShowTooltip(false)}
        className={clsx(
          'relative aspect-[16/10] h-full w-full min-w-[25rem] max-w-[25rem] rounded-xl !p-2.5 shadow-2xl',
          { 'hover:cursor-pointer': isCreateButton },
        )}
        style={{ background: primaryColor }}>
        <div className='grid w-full grid-cols-3 space-x-4 overflow-hidden'>
          <TwTitleCardHome
            style={{
              color: primaryColor && getAdaptedTextColor(primaryColor),
            }}
            className='col-span-2 line-clamp-3 overflow-hidden !font-bold !leading-[inherit]'>
            {name || 'Create your rewards program'}
          </TwTitleCardHome>
          <div className='col-span-1 flex items-start justify-end'>
            <div className='h-25 w-28'>
              {(logo && (
                <img
                  src={logo}
                  alt='logo'
                  className='h-full w-full object-contain'
                />
              )) || <UnknownSVG className='h-full w-full fill-stroke-gray' />}
            </div>
          </div>
        </div>

        <div className='flex items-end justify-between gap-x-6'>
          {/* hidden when on program edit page, program create page, and dashboard merchant page when don't have data */}
          {(isCreateButton && (
            <div className={'flex h-4 items-center gap-x-1 text-sm'}>
              <PlusSVG
                className='fill-stroke-gray-500'
                width={20}
              />
              Create
            </div>
          )) || (
            <div className={`flex items-center gap-x-2 ${points.length < 11 && 'mb-8'}`}>
              <div className='flex flex-wrap justify-center gap-2'>
                {points.map((item, index) => {
                  return (
                    <div
                      className={clsx(
                        `relative flex h-6 w-6 items-center justify-center rounded-full border-2 border-light-secondary
                        bg-white text-sm font-medium text-gray-800 transition-colors duration-300`,
                        { 'hover:cursor-pointer hover:bg-slate-300': editable },
                      )}
                      style={{ borderColor: secondaryColor }}
                      onClick={() => {
                        if (editable && onSessionMarkedPointsChange) {
                          onSessionMarkedPointsChange(
                            (sessionMarkedPoints ?? storedMarkedPoints ?? 0) > index ?
                              (sessionMarkedPoints ?? storedMarkedPoints ?? 0) - 1
                            : (sessionMarkedPoints ?? storedMarkedPoints ?? 0) + 1,
                          )
                        }
                      }}
                      key={`point-${item}`}>
                      {!isPreview && !!sessionMarkedPoints && sessionMarkedPoints > index && (
                        <StarSVG className='absolute -top-2 h-10 w-10' />
                      )}
                      {!editable &&
                        ((!!sessionMarkedPoints && sessionMarkedPoints <= index) ||
                          !sessionMarkedPoints) &&
                        item}

                      {editable &&
                        ((!!sessionMarkedPoints && sessionMarkedPoints <= index) ||
                          sessionMarkedPoints === 0) && (
                          <FaPlus
                            className='fill-stroke-gray-500'
                            width={20}
                          />
                        )}
                    </div>
                  )
                })}
              </div>
              {points.length > 0 && (
                <div>
                  <div
                    onClick={() => {
                      if (isPreview) {
                        setShowTooltip(true)
                        setTimeout(() => setShowTooltip(false), 2000)
                      } else if (onClickClaim && isClaimButton) {
                        onClickClaim()
                      }
                    }}
                    className={clsx(
                      `flex h-16 w-16 items-center justify-center rounded-full border-2 bg-white transition-colors
                      duration-300 hover:cursor-default`,
                      {
                        'border-light-secondary': !secondaryColor,
                        'hover:cursor-pointer': isClaimButton || isPreview,
                      },
                    )}
                    style={secondaryColor ? { borderColor: secondaryColor } : undefined}>
                    {!isCreate && (
                      <span className='text-sm font-medium uppercase'>
                        {(
                          (sessionMarkedPoints ?? 0) >= points.length ||
                          (storedMarkedPoints ?? 0) >= points.length
                        ) ?
                          'Claim'
                        : offers?.label}
                      </span>
                    )}
                    {isCreate && offers?.label && (
                      <span className='text-sm font-medium uppercase'>{offers?.label}</span>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className='flex justify-end'>
            <ColorAdaptingLogo
              backgroundColor={primaryColor ?? '#666666'}
              height={24}
              width={100}
            />
          </div>
        </div>
        {isPreview && (
          <div className='pointer-events-none absolute inset-0 flex items-center justify-center overflow-hidden opacity-80'>
            <div
              className='absolute whitespace-nowrap text-[4rem] font-bold'
              style={{
                transform: 'rotate(-30deg)',
                textShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                color: secondaryColor || '#fff',
              }}>
              PREVIEW
            </div>
          </div>
        )}
      </TwCardHome>
      {description && (
        <Tooltip
          show={showTooltip}
          message={description}
        />
      )}
    </Transition>
  )
}

function simplifyColor(primaryColor: string): 'black' | 'white' | 'greenlight' | 'greendark' {
  const closest = getClosestColor(primaryColor, [
    { color: '#000000', weight: 0.7 }, // black (slightly preferred)
    { color: '#FFFFFF', weight: 0.3 }, // white (slightly less preferred)
    { color: '#00A491', weight: 1 }, // greendark
    { color: '#68FFEF', weight: 1 }, // greenlight
  ])

  const color = typeof closest === 'string' ? closest : closest.color
  if (color === '#000000') return 'black'
  if (color === '#FFFFFF') return 'white'
  if (color === '#00A491') return 'greendark'
  if (color === '#68FFEF') return 'greenlight'
  return 'greendark'
}

function ColorAdaptingLogo(props: { backgroundColor: string } & React.SVGProps<SVGSVGElement>) {
  const color = simplifyColor(props.backgroundColor)
  if (color === 'black') return <LogoBlackBg {...props} />
  if (color === 'white') return <LogoWhiteBg {...props} />
  if (color === 'greendark') return <LogoGreenBg {...props} />
  if (color === 'greenlight') return <LogoGreenLightBg {...props} />
  return <LogoGreenBg {...props} />
}
function getAdaptedTextColor(backgroundColor: string) {
  const color = simplifyColor(backgroundColor)
  if (color === 'black') return '#fff'
  if (color === 'white') return '#000'
  if (color === 'greendark') return '#fff'
  if (color === 'greenlight') return '#000'
  return '#fff'
}

export default Card
